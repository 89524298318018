/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #18434a;
  --ion-color-primary-rgb: 24,67,74;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #153b41;
  --ion-color-primary-tint: #2f565c;

  --ion-color-secondary: #eba72d;
  --ion-color-secondary-rgb: 235,167,45;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #cf9328;
  --ion-color-secondary-tint: #edb042;

  --ion-color-tertiary: #0e1817;
  --ion-color-tertiary-rgb: 14,24,23;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #0c1514;
  --ion-color-tertiary-tint: #262f2e;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #DFF1F1;
  --ion-toolbar-background: #DFF1F1;

  color: #ffffff !important;

}

body.dark {

  --ion-color-secondary: #def1f1;
  --ion-color-secondary-rgb: 222, 241, 241;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c3d4d4;
  --ion-color-secondary-tint: #e1f2f2;

  --ion-color-primary: #eba72d;
  --ion-color-primary-rgb: 235, 167, 45;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #cf9328;
  --ion-color-primary-tint: #edb042;

  --ion-color-tertiary: #0d1916;
  --ion-color-tertiary-rgb: 13,25,22;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #0b1613;
  --ion-color-tertiary-tint: #25302d;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 34, 34;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 244, 244;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;


  
  --ion-background-color: #18434a;
  --ion-background-color-rgb: 24, 67, 74;

  --ion-item-background: #18434a;
  --ion-toolbar-background: #18434a;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #192522;
  --ion-color-step-100: #25302d;
  --ion-color-step-150: #313c39;
  --ion-color-step-200: #3d4745;
  --ion-color-step-250: #4a5350;
  --ion-color-step-300: #565e5c;
  --ion-color-step-350: #626a68;
  --ion-color-step-400: #6e7573;
  --ion-color-step-450: #7a817f;
  --ion-color-step-500: #868c8b;
  --ion-color-step-550: #929896;
  --ion-color-step-600: #9ea3a2;
  --ion-color-step-650: #aaafad;
  --ion-color-step-700: #b6bab9;
  --ion-color-step-750: #c3c6c5;
  --ion-color-step-800: #cfd1d0;
  --ion-color-step-850: #dbdddc;
  --ion-color-step-900: #e7e8e8;
  --ion-color-step-950: #f3f4f3;
}

body.dark ion-toggle {
  --background: #ccc;
  
}
