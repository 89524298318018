
/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

p {
  font-size: 1rem;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

.sc-ion-card-ios-h {
  margin-left: 0;
  margin-right: 0;
}

.card-margin {
  margin: 10px;
}

.no-margin {
  margin: 0;
}

.title-default {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header-ios ion-toolbar:last-child {
  /* --border-width: 0 0 1px;
  --border-color: #000000; */
  padding: 5px 0;
}

ion-label > h4.list > div {
  margin: 5px 5px 5px 0;
  border-right: 1px solid black;
  padding-right: 5px;
  margin-right: 5px;
  display: inline-block;
}

ion-label > h4.list > div:last-child {
  border-right: 0;
}

body.backdrop-no-scroll {
  overflow: auto;
}

body .sq-payment-form {
  width: 100%;
}

/* CARDS */

/* .sc-ion-card-ios-h {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid black;
  border-radius: 0;
} */

/* BUTTONS */
/* ion-button {
  --border-radius: 0 !important;
} */

/* ion-item {
  --inner-border-width: 0px 0px 1px 0px;
  --border-color: #000000;
} */

@media (min-width: 576px) {
  #primaryMenu, #secondaryMenu {
    max-width: 22%;
    min-width: 200px;
  }
}


@media only screen and (min-height: 768px) and (min-width: 768px) {
  .opel-modal-large {
    --height: 100%;
    --width: 78%;
    justify-content: flex-start;
    --box-shadow: none;
  }
}

.opel-full-height {
  height: 100%;
  overflow: hidden;
}

.full-height {
  height: 100%;
}

.opel-scroll {
  overflow: scroll;
}

ion-card .firebaseui-container {
  max-width: none;
  box-shadow: none;
  background-color: transparent;
}

ion-card .mdl-button--raised.mdl-button--colored {
  color: var(--ion-color-contrast);
  background-color: var(--ion-color-secondary, #0cd1e8) !important;
}

ion-card.sc-ion-card-ios-s .firebaseui-container img {
  width: auto;
}

ion-card.sc-ion-card-ios-s .firebaseui-container .firebaseui-idp-button {
  max-width: none;
}

@-webkit-keyframes fadeIn { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; opacity: 1\9; /* IE9 only */ } to { opacity:1; } }

.fade-in {
	opacity:0;  /* make things invisible upon start */
	-webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
	-moz-animation:fadeIn ease-in 1;
	animation:fadeIn ease-in 1;

	-webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
	-moz-animation-fill-mode:forwards;
	animation-fill-mode:forwards;

	-webkit-animation-duration:1s;
	-moz-animation-duration:1s;
	animation-duration:1s;
}

.dark .item-has-focus.ion-color-secondary .label-floating.sc-ion-label-md-h {
  color: var(--ion-color-tertiary)
}

.sq-creditcard {
  background: var(--ion-color-tertiary) !important;
  font-weight: bold;
}

.credit-card-wrapper .sq-label {
  color: var(--ion-color-tertiary)
}

.container {
  max-width: 900px;
  margin: 0 auto;
}

ion-content .raf-activity__content p,
ion-content .raf-activity__content p,
ion-content .raf-user-bar p, 
ion-content p.raf-reaction-icon__label,
ion-content .raf-comment-item__content p {
  color: var(--ion-color-tertiary);
}

ion-content .raf-comment-item__content p {
  font-weight: normal;
}

ion-content .raf-activity__content {
  margin: 0;
  padding: 0;
}

ion-content .raf-activity {
  background-color: var(--ion-color-secondary);
  margin: 20px 0;
  border-radius: 4px;
}

ion-content .raf-like-button,
ion-content .raf-comment-item {
  border-bottom: 0.1px solid var(--ion-color-tertiary);
}

ion-content .raf-comment-item:last-child {
  margin-bottom: 8px;
  border-bottom: 0;
}

ion-content .rta {
  border-radius: 0;
}

ion-content .raf-panel {
  background-color: var(--ion-color-secondary);
}

ion-content .raf-button--primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-tertiary);
}

ion-content .raf-button--primary:disabled {
  background-color: var(--ion-color-primary);
}

ion-content .raf-comment-item__author {
  color: var(--ion-color-primary);
}

body ion-content [class^='raf-'],
body ion-content [class*=' raf-'] {
  font-family:  Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

ion-content .raf-button--info {
  background-color: var(--ion-color-tertiary);
  margin-top: 10px;
}

ion-content .raf-comment-field .raf-textarea .raf-textarea__textarea:focus {
  background-color: transparent;
}

ion-content .raf-comment-field .raf-textarea .raf-textarea__textarea {
  background-color: transparent;
  border: solid var(--ion-color-secondary);
  border-width: 1px 0 1px 1px;
  color: var(--ion-color-secondary);
}

ion-content .raf-user-bar {
  border-bottom: 0.1px solid var(--ion-color-tertiary);
}

ion-content .raf-panel {
  margin: 16px 0 16px;
}

ion-content .raf-panel-header {
  border-bottom: 1px solid #ccc;
  padding-top: 0;
  padding-bottom: 0;
}

ion-content .raf-card__title {
  color: var(--ion-color-secondary);
}

ion-content .raf-card {
  border-color: transparent;
}

ion-content .raf-card .raf-card__content {
  background-color: var(--ion-color-primary);
}

ion-content .raf-card .raf-card__title {
  font-weight: bold;
}

ion-content .raf-gallery {
  border: 0;
  height: auto
}

ion-content button.raf-new-activities-notification {
  background: transparent;
  border: 1px solid var(--ion-color-tertiary);
  box-shadow: none;
}

ion-content button.raf-new-activities-notification a {
  color: var(--ion-color-secondary);
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 #stripePayment .StripeElement {
  box-sizing: border-box;
  height: 60px;
  padding: 18px 12px;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
  border-bottom: #ccc 1px solid;
}

#stripePayment .StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

#stripePayment .StripeElement--invalid {
  border-color: #fa755a;
}

#stripePayment .StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}